.App {
    text-align: center;
    /*background-color: #f5f5f5;*/
}

.App-logo {
    height: 10vmin;
    pointer-events: none;
}


.App-header {

    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}


.emoji-bar {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.card {
    max-width: 400px;
}

.detail-link {
    cursor: pointer;
}



.container {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.container::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}
